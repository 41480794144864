import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm(
        "service_v2f77s3",
         "template_pub05ha",
          form.current, 
          "FxUUCV0ayLuTywdPs"
        )
      .then(
        (result) => {
          console.log(result.text);
          alert("message sent")
          window.location.reload(false)
      },
       (error) => {
          console.log(error.text);
          alert("message fail to send")
      }
    );
  };

    return (
    <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass} 
                    strArray={['C', 'o','n','t','a','c','t', ' ','U', 's']} 
                    idx={15}/>
                </h1> 
                <p>
                    We are interest in freelance opportunities - especially ambitious or 
                    large projects. However, if you have other request or question,
                    don't hesitate to contact Us using below form either.
                </p>
                <div className='contact-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type="text" name="name" placeholder='Name' required />
                            </li>
                            <li className='half'>
                                <input type="email" name="email" placeholder='email' required />
                            </li>
                           
                            <li>
                                <textarea placeholder="Message" name="message" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className="flat-button" value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>  
            </div>
            <div className='info-map'>
                Group 10 Developers,
                <br />
                Ghana
                <br />
                Kwadaso Estate <br />
                Kumasi <br />
                <span>michaelobeng021@gmail.com</span>
            </div>
            <div className="map-wrap">
                <MapContainer center={[6.6942905, -1.6477917]} zoom={13}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={[6.6942905, -1.6477917]}>
                        <Popup>We live here, let us be your website designer </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
        <Loader type="pacman" />
    </>)
}

export default Contact